import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
    name:'Layout',
    component:()=>import("../views/Layout"),
    children: [
      {
        path: '/home',
        name: 'Home',
        component:()=>import("../views/1/Home")
      },
      {
        path: '/search/:id',
        name: 'Search',
        component:()=>import("../views/1/Search")
      },
      {
        path: '/load/:id/:to',
        name: 'LoadPage',
        component:()=>import("../views/1/LoadPage")
      },
      {
        path: '/hj/:id',
        name: 'HJ',
        component:()=>import("../views/1/HJ")
      },
      {
        path: '/series/:id',
        name: 'Series',
        component:()=>import("../views/2/Series")
      },
      {
        path: '/sp/:id',
        name: 'SP',
        component:()=>import("../views/3/SP")
      },
      {
        path: '/u',
        name: 'U',
        component:()=>import("../views/1/U")
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

// router.beforeEach((to,from,next)=>{
//   if(to.path === from.path) {
//     console.log("路径一样")
//     console.log(to.path)
//     console.log(from.path)
//   }
//   else {
//     console.log("路径不同")
//     console.log(to.path)
//     console.log(from.path)
//     next();
//   }
//   const token=JSON.parse(window.localStorage.getItem('token'))
//   if(to.path==='/login') {
//     window.localStorage.removeItem('token');
//     window.localStorage.removeItem('user');
//     next();
//   }
//   else if(to.path==='/newest' || to.path==='/recommend' || to.path==='/top') {
//     clearAnchor();
//     next();
//   }
//   else {
//     next()
//   }
// })
// function clearAnchor() {
//   if (window.location.hash) {
//     history.replaceState(null, document.title, window.location.pathname + window.location.search);
//     window.location.reload();
//   }
// }

export default router